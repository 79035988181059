import React from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { Box, FormControl, FormGroup } from '@material-ui/core';
import Config from "../util/Config"
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import HeaderAppBar from "./header"
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';

class ConfigSettings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      somAtivo: false,

      buscarPedidos: true,
      buscarChamados: true,

      configuracoes: Config.getConfiguracaoImpressoras()
    }

    this.salvarConfiguracoes = this.salvarConfiguracoes.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeChecked = this.handleChangeChecked.bind(this)

    this.handleChangeBuscaChamados = this.handleChangeBuscaChamados.bind(this)
    this.handleChangeBuscaPedidos = this.handleChangeBuscaPedidos.bind(this)
  }

  componentDidMount() {//quando inicia a tela chama essas funções
    this.verificaSomAtivo();
    this.verificaBuscasAtivas();
  }

  verificaSomAtivo() {
    let som = localStorage.getItem("_somAtivo");
    if (som === "true") {
      this.setState({ somAtivo: true })
    } else {
      this.setState({ somAtivo: false })
    }
  }

  salvarConfiguracoes() {
    //console.log("Salvando as configurações")
    let { configuracoes } = this.state
    Config.salvaConfiguracoes(configuracoes)
    this.props.enqueueSnackbar('Configurações salvas com sucesso.', { variant: 'success' })
    this.props.history.push('/home')
  };

  handleChange(event) {
    let { configuracoes } = this.state
    configuracoes.gerais[event.target.name] = event.target.value;
    this.setState({ configuracoes })
    //console.log(configuracoes)
  };

  handleChangeChecked(event) {
    let { configuracoes } = this.state
    configuracoes.gerais[event.target.name] = event.target.checked;
    this.setState({ configuracoes })
    //console.log(configuracoes)
  };

  handleChangeSom = (e) => {
    //console.log(e.target.checked);

    this.setState({ somAtivo: e.target.checked });
    localStorage.setItem("_somAtivo", e.target.checked)
  }

  //Pega no local storage os valores salvos de buscar chamados e buscar pedidos e depois salva no state
  verificaBuscasAtivas() {
    let chamados = localStorage.getItem("_buscarChamados") === null ? "true" : localStorage.getItem("_buscarChamados");
    let pedidos = localStorage.getItem("_buscarPedidos") === null ? "true" : localStorage.getItem("_buscarPedidos");

    if (chamados === "true") {
      this.setState({ buscarChamados: true })
    } else {
      this.setState({ buscarChamados: false })
    }

    if (pedidos === "true") {
      this.setState({ buscarPedidos: true })
    } else {
      this.setState({ buscarPedidos: false })
    }
  }

  handleChangeBuscaChamados() {//alterna o status do buscar chamados
    let { buscarChamados } = this.state
    this.setState({ buscarChamados: !buscarChamados })

    localStorage.setItem("_buscarChamados", !buscarChamados)
    /*
    if (!buscarChamados) {
      console.log("Busca de chamados Iniciada")
    } else {
      console.log("Busca de chamados Pausada")
    }*/
  }

  handleChangeBuscaPedidos() {//alterna o status do buscar pedidos
    let { buscarPedidos } = this.state
    this.setState({ buscarPedidos: !buscarPedidos })

    localStorage.setItem("_buscarPedidos", !buscarPedidos)
    /*
    if (!buscarPedidos) {
      console.log("Busca de pedidos Iniciada")
    } else {
      console.log("Busca de pedidos Pausada")
    }*/
  }

  render() {

    const { configuracoes, somAtivo } = this.state;
    const { buscarChamados, buscarPedidos } = this.state

    return (

      <div>
        <HeaderAppBar />

        <Container maxWidth="sm">

          <Box pt={10}>

            <Typography variant="h4" component="h1">
              Configurações gerais
            </Typography>

            <Typography >
              Outras configurações do aplicativo
            </Typography>


          </Box>

          <Box pt={5} pb={5}>

            <Grid container >

              <Grid xs={6} item  >
                <Box pt={4}>
                  <Typography variant="h6" >
                    Configurações do monitor
                  </Typography>
                </Box>
              </Grid>

              <Grid xs={12} item  >
                <TextField
                  id="poolingTime"
                  label="Intervalo do pooling"
                  margin="normal"
                  name="poolingTime"
                  fullWidth
                  value={configuracoes.gerais.poolingTime}
                  type="number"
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Segundos</InputAdornment>,
                  }}
                />

              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position">
                    <FormControlLabel
                      style={{ marginTop: "10px" }}
                      control={<Switch size="small" checked={somAtivo} onChange={e => this.handleChangeSom(e)} />}
                      label="Ativar som para pedidos"
                    />
                    <FormControlLabel
                      value="end"
                      control={<Switch size="small" checked={buscarPedidos} onChange={this.handleChangeBuscaPedidos} />}
                      label="Busca de Pedidos"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="end"
                      control={<Switch size="small" checked={buscarChamados} onChange={this.handleChangeBuscaChamados} />}
                      label="Busca de Chamados"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>


              <Grid xs={6} item  >
                <Box pt={4}>
                  <Typography variant="h6" >
                    Integração
                  </Typography>
                </Box>
              </Grid>

              <Grid xs={12} item  >
                <FormControlLabel
                  control={
                    <Switch checked={configuracoes.gerais.ativarIntegracao} value={configuracoes.gerais.ativarIntegracao}
                      onChange={this.handleChangeChecked}
                      name="ativarIntegracao" />
                  }
                  label="Ativar integração com Sischef"
                />

              </Grid>

              <Grid xs={12} item  >
                <TextField
                  id="responsavelImpressao"
                  label="Responsável pela impressão"
                  margin="normal"
                  select
                  name="responsavelImpressao"
                  fullWidth
                  value={configuracoes.gerais.responsavelImpressao}
                  onChange={this.handleChange}
                >
                  <MenuItem value="QRPEDIR">
                    Qrpedir faz a impressão do pedido
                  </MenuItem>
                  <MenuItem value="SISCHEF">
                    Sischef faz a impressão do pedido
                  </MenuItem>
                  <MenuItem value="QRPEDIR_SISCHEF">
                    Ambos fazem a impressão
                  </MenuItem>
                </TextField>



              </Grid>

              <Grid xs={12} item  >
                <TextField
                  id="urlApiIntegracao"
                  label="URL/IP da API integração Sischef"
                  margin="normal"
                  name="urlApiIntegracao"
                  fullWidth
                  value={configuracoes.gerais.urlApiIntegracao}
                  onChange={this.handleChange}
                />

              </Grid>

            </Grid>


          </Box>


          <Button variant="contained" color="primary" onClick={this.salvarConfiguracoes} >
            Salvar configurações
          </Button>


        </Container>
      </div>
    )
  }
}


export default withSnackbar(ConfigSettings)