
export const ipc = {
    sendSync : (...args) => {
        try{
            return window.electronBridge.ipcRenderer.sendSync(...args)
        }catch(e){
            console.error("Não é possível acessar funções do electron", e)
            return null
        }
    },

    openExternal : (...args) => {
        try{
            return window.electronBridge.shell.openExternal(...args)
        }catch(e){
            console.error("Não é possível acessar funções do electron", e)
            throw e
        }
    },

    remote : (window.electronBridge !== undefined ? window.electronBridge.remote : null),
    ipcRenderer : (window.electronBridge !== undefined ? window.electronBridge.ipcRenderer : null),
    shell : (window.electronBridge !== undefined ? window.electronBridge.shell : null),
    log : (window.electronBridge !== undefined ? window.electronBridge.log : null)
} 