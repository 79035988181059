
import Config from "../util/Config" 
import AuthService from '../AuthService'

export default class PedidoIntegracaoHelper {

    constructor() {
        this.converterPedido = this.converterPedido.bind(this) 
        this._enviarPedidosParaSischef = this._enviarPedidosParaSischef.bind(this)
        this._notificarPedidosComoIntegrados = this._notificarPedidosComoIntegrados.bind(this)
        this._enviarPedidosParaSischefENotificarIntegracao = this._enviarPedidosParaSischefENotificarIntegracao.bind(this)
        this.removeCaracteresEspeciais = this.removeCaracteresEspeciais.bind(this)
    }


    converterPedido(pedidoQrpedir){
        //console.log("pedido qrpedir a ser convertido", pedidoQrpedir)

        let empresa = pedidoQrpedir.identificador.empresa;
        //let tipoIdentificadorPrincipal = empresa.tipoIdentificadorPrincipal;
        let tipoIdentificadorSecundario = empresa.tipoIdentificadorSecundario;

        /* status item sischef - P - pendente de impressao ; F - finalizar */
        let statusImpressao = "P"

         /* responsavelImpressao = QRPEDIR, SISCHEF, QRPEDIR_SISCHEF */
        let responsavelImpressao = Config.getConfigGerais().responsavelImpressao
        if ('QRPEDIR' === responsavelImpressao){
            statusImpressao = 'F'
        }
        if ('SISCHEF' === responsavelImpressao){
            statusImpressao = 'P'
        }
        if ('QRPEDIR_SISCHEF' === responsavelImpressao){
            statusImpressao = 'P'
        }
         

        /* NUM DO IDENT PRINCIPAL */
        let identificador = pedidoQrpedir.identificador.numero
        let identificadorSecundario = pedidoQrpedir.identificadorSecundario

        /* CASO O IDENT SEC. SEJA DELIVERY, O SEQUENCIAL (IDENT SECUND) VIRA O PRINCIPAL */
        let isDelivery = (pedidoQrpedir.identificador.tipo === "DELIVERY")

        if(isDelivery){
            identificador = identificadorSecundario;
            identificadorSecundario = pedidoQrpedir.identificador.numero;
        }else{

            if (tipoIdentificadorSecundario === 'COMANDA'){
                /* CASO O IDENT SEC. SEJA COMANDA, UTILIZA ELE (e manda a mesa como secundario) */
                if (pedidoQrpedir.identificadorSecundario){
                    identificador = pedidoQrpedir.identificadorSecundario
                    identificadorSecundario = pedidoQrpedir.identificador.numero
                }
            }

        }

         /* INICIO CONVERSAO EM FORMATO SISCHEF */
        let pedidoConvertido = {
            codigoReferencial: `QR_${pedidoQrpedir.id}`,
            idOrigem: pedidoQrpedir.id,
            identificacao: identificador,
            pessoa: pedidoQrpedir.pessoa,
            delivery: isDelivery,
            valorTaxaEntrega: pedidoQrpedir.valorTaxaEntrega,
            identificacaoLocal: identificadorSecundario,
            dataHora: pedidoQrpedir.dataPedido,
            descontoPedidoDistribuir: pedidoQrpedir.valorDesconto,
            tipoPedido: "QR",
            troco: pedidoQrpedir.troco,
            itens: [],
            observacao: `${pedidoQrpedir.dispositivo} ${pedidoQrpedir.descricao ? ' - ' + this.removeCaracteresEspeciais(pedidoQrpedir.descricao) : ''}`
        }
        
        pedidoQrpedir.itens.map(item => {
            
            let itemConvertido = {
                codigoReferencial: `QR_ITEM_` + item.id ,
                observacao: this.removeCaracteresEspeciais(item.observacao) || null,
                dataHora: pedidoQrpedir.dataPedido,
                codigoReferencialProduto: item.produto.codigoExterno || null,
                quantidade: item.quantidade,
                valorUnitario: item.valorUnitario,
                valorDesconto: item.valorDesconto,
                valorTotal: item.valorTotal,
                status: statusImpressao,
                descricaoProduto: `${item.produto.nome}`,
                nomeCliente: pedidoQrpedir.nomeCliente,
                subItens: []
            }

            item.subItens.map(subItem => {

                let subItemConvertido = {
                    codigoReferencial: 'QR_SUBITEM' + subItem.id,
                    observacao: subItem.observacao,
                    dataHora: pedidoQrpedir.dataPedido,
                    codigoReferencialProduto: subItem.codigoExterno || null,
                    quantidade: subItem.quantidade,
                    valorUnitario: subItem.valorUnitario,
                    valorDesconto: subItem.valorDesconto,
                    valorTotal: subItem.valorTotal,
                    status: statusImpressao,
                    descricaoProduto: subItem.descricao,
                }

                itemConvertido.subItens.push(subItemConvertido)
                return null;
            })

            pedidoConvertido.itens.push(itemConvertido)
            return null;
        })


        //console.log("PEDIDO CONVERTIDO", pedidoConvertido)

        return pedidoConvertido
    }
    
    _enviarPedidosParaSischef(pedidos){

        return new Promise((resolve, reject) =>{

            if (!pedidos){
                return resolve([])
            }
    
            if (pedidos.length === 0){
                return resolve([])
            }
    
            let pedidosConvertidos = []
            pedidos.map(pedido => {
                let pedidoConvertido = this.converterPedido(pedido)
                pedidosConvertidos.push(pedidoConvertido)
                return null;
            })

            let urlApiIntegracao = Config.getConfigGerais().urlApiIntegracao
                    
            let service = new AuthService(urlApiIntegracao)
            service
            .post('/api/pedido/saveAll', pedidosConvertidos)
            .then(resp => { 
                
                return resolve(resp)

            }).catch(err => {

                return reject(err);

            })

        })
        
    }


    _notificarPedidosComoIntegrados(retornoSischef){
        
        /* PEDIDOS A SEREM NOTIFICADOS DA INTEGRACAO */
        return new Promise((resolve, reject) =>{
            
            if (!retornoSischef){
                return resolve([])
            }

            if (retornoSischef.length === 0){
                return resolve([])
            }

            let pedidosIntegrados = []

            retornoSischef.map(pedidoSischef => {
                if (pedidoSischef.idOrigem){
                    pedidosIntegrados.push({id: pedidoSischef.idOrigem}) 
                }
                return null;
            })
            
            /* SE VAZIO JA RETORNA */
            if (pedidosIntegrados.length === 0){
                return resolve([])
            }

            let service = new AuthService()

            service
            .post('/pedido/marcarComoIntegrado', pedidosIntegrados)
            .then(resp => { 
            
                return resolve(resp)
    
            }).catch(err => {
            
                return reject(err);

            })
            
        })

    }

    _enviarPedidosParaSischefENotificarIntegracao(pedidos, onSucces, onError){

        //PASSO 1 - ENVIAR SISCHEF

        this._enviarPedidosParaSischef(pedidos)
        .then(respostaSischef => {

            this._notificarPedidosComoIntegrados(respostaSischef)
                .then(retornoNotificados => {
                    
                    onSucces(retornoNotificados)

                }).catch(err => {
                    onError("Erro ao notificar como integrado", err)
                })

        }).catch(err => {
            onError("Erro ao enviar pedido para o Sischef", err)
        })


    }

    removeCaracteresEspeciais(value){
        if(value){
            return value.replace(/['"`´\\]/g, "" )
        }
        return null
    }

}