import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import AuthService from "../AuthService";

export default {
    /**
     * 
     * @param {
     * "currency" para formato monetário
     * "percent" para formato percentual} style 
     * @returns Valor formatado
     */
    optionsCurrency(style = 'currency') {

        const moeda = this.getMoedaEmpresa()

        let options = {
            currencySymbol: style==='currency'? 'R$': '%',
            decimalPlaces: 2,
        }

         //Japao
         if (moeda === 'IENE') {
            options = {
                currencySymbol: style==='currency'? '￥': '%',
                decimalPlaces: 0,
            }
        }

        return options
    },

    /**
     * @param {Valor a ser formatado} value 
     * @param {"decimal" para formato de número simples, 
     * "currency" para formato monetário
     * "percent" para formato percentual; 
     * o padrão é "currency"} style 
     * @returns Valor formatado
     */
    formatNumber(value, style = 'currency') {
         // "￥123,357"=  {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(123356.65)}
        // "R$ 123.356,65" = {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(123356.65)}
        const moeda = this.getMoedaEmpresa()

        let param = {
            locales: 'pt-BR',
            options: {
                style,
                currency: 'BRL',
                useGrouping: true,
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            }
        }

        //Japao
        if (moeda === 'IENE') {
            param = {
                locales: 'ja-JP',
                options: {
                    style,
                    currency: 'JPY',
                    useGrouping: true,
                    maximumFractionDigits: 0,
                }
            }
        }

       
        if (value !== null && value !== undefined) {
            return new Intl.NumberFormat(param.locales, param.options).format(value)
        } else {
            return value
        }
    },
      
    formataMonetario(value) {
        if (!value && 0 !== value)
            return '0.00'
        let val = parseFloat(value)
        return val.toFixed(2).replace('.', ',');
    },

    formatarDataHora(data, timezone = "-03:00") {
        if (!data)
            return ''
        try {
            let date = utcToZonedTime(data, timezone);

            return format(date, 'dd/MM/yy HH:mm')
        } catch (e) {
            console.log('falha ao converter data ', data)
            return ''
        }
    },

    formatarData(data, timezone = "-03:00") {
        if (!data)
            return ''
        try {
            let date = utcToZonedTime(data, timezone);

            return format(date, 'dd/MM/yyyy')
        } catch (e) {
            console.log('falha ao converter data ', data)
            return ''
        }
    },

    onlyDigits(numbers) {
        if (!numbers) {
            return "";
        }

        let onlyNumbers = numbers.replace(/\D/g, "")

        return onlyNumbers
    },

    getMoedaEmpresa() {
        let service = new AuthService()

        return service.getProfile()?.empresa?.moeda
    }
}