import PedidoUtils from "./PedidoUtils"
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import util from ".";
require('string-format-js')

const _BL_ = "";

export default class Report {

    static printPedido(pedido, cols, chaveLocalNotificacao, localImpressaoObj) {
        //console.log('printPedido', pedido)
        if (pedido === null) {
            return null;
        }

        cols = cols - _BL_.length

        let identificador = pedido?.identificador !== null ? pedido.identificador.numero : ""
        let labelIdentificador = pedido.identificador !== null ? pedido.identificador.tipo : "IDENTIFICADOR"
        let descricaoLocalImpressao = localImpressaoObj !== null ? chaveLocalNotificacao : localImpressaoObj.descricao
        let empresa = pedido.empresa;
        let timezone = empresa.timezone;

        let identificadorSecundario = pedido.identificadorSecundario || ''
        let labelIdentificadorSecundario = 'COMANDA'
        let tipoIdentificadorSecundario = empresa.tipoIdentificadorSecundario

        if ("NAO_SE_APLICA" !== tipoIdentificadorSecundario) {
            labelIdentificadorSecundario = tipoIdentificadorSecundario
        }

        let headerPrincipal = ''
        let headerSecundaria = ''

        if (pedido.identificador.tipo === "SEQUENCIAL") {
            headerPrincipal = "SENHA " + identificadorSecundario
            headerSecundaria = "PONTO " + identificador
        } else if (pedido.identificador.tipo === "DELIVERY") {
            headerPrincipal = "PEDIDO DELIVERY"
            headerSecundaria = "SEQUENCIAL " + identificadorSecundario
        } else {
            headerPrincipal = labelIdentificador + " " + identificador;
            headerSecundaria = labelIdentificadorSecundario + " " + identificadorSecundario;
        }

        let template = ""
            .concat(_BL_ + Report.stCenter(empresa.nome, cols))
            .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
            .concat(_BL_ + "|" + Report.stCenter(`${headerPrincipal}`, cols - 2)) + "|"
                .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
                .concat(_BL_ + Report.stCenter(`${headerSecundaria}`, cols))
                .concat(_BL_ + Report.fill(" ", cols))
                .concat(_BL_ + "LOCAL:     " + Report.stR(descricaoLocalImpressao, cols - 11))
                .concat(_BL_ + "DATA:      " + Report.toDT(pedido.dataPedido, cols - 11, timezone))

        if (pedido.tipoDelivery === "ENTREGAR" && pedido.valorTaxaEntrega) {
            template = template
                .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
                .concat(_BL_ + "|" + Report.stCenter("ENTREGAR", cols - 2)) + "|"
                    .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
        } else if (pedido.tipoDelivery === "RETIRAR_LOCAL") {
            template = template
                .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
                .concat(_BL_ + "|" + Report.stCenter("HEI!!! EU VOU BUSCAR.", cols - 2)) + "|"
                    .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
        }

        if (pedido.horarioAgendamento) {
            template = template.concat(_BL_ + "AGENDADO:  " + Report.toDT(pedido.horarioAgendamento, cols - 11, timezone))
        }

        let pessoa = pedido.pessoa

        if (pessoa) {
            let multiplyColumName = Math.ceil((pessoa.nome || "").length / cols)
            template = template
                .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
                .concat(_BL_ + "|" + Report.stCenter("DADOS CLIENTE", cols - 2) + "|")
                .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
                .concat(_BL_ + "" + Report.stL(pessoa.nome.toUpperCase(), (cols * multiplyColumName)))
                .concat(_BL_ + "FONE:" + Report.stL(pessoa.telefone, cols - 5))

            if (pedido.endereco) {
                let multiplyColumnComp = Math.ceil((pedido.complemento || "").length / cols); // utiliza o tamanho da frase e da coluna (cols) para definir o tamanho da frase na hora de impressão
                let endereco = pedido.endereco + ", " + pedido.numero + ", " + pedido.bairro + ", " + pedido.municipio.nomeComEstado.toUpperCase(); //salva na variavel endereco os itens que definem o endereço do comprador com rua, numero, bairro e municipio com estado          
                let multiplyColumn = Math.ceil((endereco || "").length / cols); // utiliza a mesma tecnica do anterior para a frase de endereço do pedido

                template = template //concatena na variavel template o endereço com quebra de linha para imprimir
                    .concat(_BL_ + Report.stCenter("ENDEREÇO", cols))
                    .concat(_BL_ + Report.stL(endereco, (cols * multiplyColumn)))

                if (pedido.complemento !== "") { // A area de complemento só será impressa caso o complemento do pedido não seja vazio, utilizando a mesma técnica de impressão anterior
                    template = template
                        .concat(_BL_ + Report.stCenter("COMPLEMENTO", cols))
                        .concat(_BL_ + Report.stL(pedido.complemento, (cols * multiplyColumnComp)))
                }
            }

            //template = template

        } else {
            let multiplyColumn = Math.ceil((pedido.nomeCliente || "").length / (cols - 11));
            if (multiplyColumn > 1) {
                template = template
                    .concat(_BL_ + "CLIENTE:   " + Report.stL(pedido.nomeCliente.toUpperCase(), (cols * multiplyColumn) - 11));
            } else {
                template = template
                    .concat(_BL_ + "CLIENTE:   " + Report.stR(pedido.nomeCliente.toUpperCase(), cols - 11));
            }
        }


        if (pedido.descricao) {

            let multiplyColumn = Math.ceil((pedido.descricao || "").length / cols);

            template = template
                .concat(_BL_ + Report.fill(" ", cols))
                .concat(_BL_ + "OBS.: " + Report.stL(pedido.descricao, (cols * multiplyColumn) - 6))
                .concat(_BL_ + Report.fill(" ", cols))
        }

        template = template
            .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
            .concat(_BL_ + "|" + Report.stCenter("ITENS", cols - 2) + "|")
            .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")


        let itens = pedido.itens;

        /* SE FOR DELIVERY OU SEQUENCIAL, IMPRIME TODOS OS ITENS */
        if (pedido.identificador.tipo !== "DELIVERY" && pedido.identificador.tipo !== "SEQUENCIAL") {

            /* CASO SEJA PASSADO UM LOCAL DE NOTIFICACAO, FILTRA OS ITENS */
            if (chaveLocalNotificacao) {
                itens = itens.filter(item => { return item.produto.localImpressao === chaveLocalNotificacao })
            }
        }

        itens.sort((a, b) => {
            const aOrdem = a.ordem;
            const bOrdem = b.ordem;
        
            if (aOrdem > bOrdem) return 1;
            if (bOrdem > aOrdem) return -1;
        
            return 0;
        });

        itens.map(item => {

            let itemLineDesc = item.produto.nome;

            let itemLineNumeros = ""
                .concat(Report.stR(item.quantidade + ' x ', 10))
                .concat(" ")
                .concat(Report.stR(util.formatNumber(item.valorUnitario, 'decimal'), 10))
                .concat(" ")
                //.concat( Report.stR(item.valorDesconto.toFixed(2), 5))
                .concat(Report.stR(util.formatNumber(item.valorTotal, 'decimal'), 12))

            let nameLengh = item.produto.nome.length
            let itemNumLength = itemLineNumeros.length
            let breakLine = true; // (nameLengh + itemNumLength) > cols

            template = template
                .concat(_BL_)
                .concat(breakLine ? Report.stL(itemLineDesc, cols) : Report.stL(itemLineDesc, cols - itemNumLength))
                .concat(breakLine ? _BL_ : "")
                .concat(breakLine ? Report.stR(itemLineNumeros, cols) : Report.stR(itemLineNumeros, cols - nameLengh));

            if (item.observacao) {
                let tamanhoTexto = item.observacao.length / cols;

                if (tamanhoTexto > 1) {
                    template = template.concat(_BL_ + Report.stL(" *OBS: " + item.observacao, (cols * Math.ceil(tamanhoTexto))))
                } else {
                    template = template.concat(_BL_ + Report.stL(" *OBS: " + item.observacao, cols))
                }


            }

            item.subItens.map(subItem => {
                let subItemLineDesc = Report.stL("  - " + subItem.descricao, cols);

                let subItemLineNumeros = Report.stR(""
                    .concat(Report.stR(subItem.quantidade + ' x ', 10))
                    .concat(" ")
                    .concat(Report.stR(util.formatNumber(subItem.valorUnitario, 'decimal'), 10))
                    .concat(" ")
                    .concat(Report.stR(subItem.valorDesconto === 0 ? "" : util.formatNumber(subItem.valorDesconto, 'decimal'), 5))
                    .concat(Report.stR(util.formatNumber(subItem.valorTotal, 'decimal'), 12)), cols);

                template = template
                    .concat(_BL_)
                    .concat(subItemLineDesc)
                    .concat(_BL_)
                    .concat(subItemLineNumeros);

                return null;
            })

            template = template.concat(_BL_ + Report.stCenter(" - - - - ", cols))
            return null;
        })

        pedido.pagamentos.map(pagamento => {
            let descFormaPagamento = pagamento.formaPagamento.descricao + " - " + util.formatNumber(pagamento.valor, 'decimal')
            let tamanho = descFormaPagamento.length
            let multiptyCols = Math.ceil(tamanho / cols)

            template = template
                .concat(_BL_ + Report.fill(" ", cols))
                .concat(_BL_ + Report.stCenter("PAGAREI NO ", cols));

            let formaPagamentoCompleteLine = Report.stL(descFormaPagamento, cols * multiptyCols);
            template = template.concat(_BL_ + formaPagamentoCompleteLine)

            return null;
        })

        /* SE TIVER CUPOM, IMPRIME */
        if (pedido.cupomDesconto) {
            template = template
                .concat(_BL_ + Report.fill(" ", cols))
                .concat(_BL_ + "CUPOM DESC.: " + Report.stL(pedido.cupomDesconto.cupom, cols - 13))
                .concat(_BL_ + Report.fill(" ", cols));
        }


        /* SE TIVER CUPOM, IMPRIME */
        if (pedido.troco && parseFloat(pedido.troco) > 0) {

            let trocoPara = parseFloat(pedido.troco)
            let valorTroco = trocoPara - PedidoUtils.total(pedido)

            template = template
                .concat(_BL_ + Report.fill(" ", cols))
                .concat(_BL_ + "LEVAR TROCO PARA: " + Report.stL(util.formatNumber(trocoPara, 'decimal'), cols - 18))
                .concat(_BL_ + " (VALOR DE TROCO: " + Report.stL(util.formatNumber(valorTroco, 'decimal') + ')', cols - 18))
                .concat(_BL_ + Report.fill(" ", cols));
        }

        /* IMPRIME O TOTAL SE FOR DELIVERY ou SEQUENCIAL */
        if (pedido.identificador.tipo === "DELIVERY" || pedido.identificador.tipo === "SEQUENCIAL") {
            template = template
                .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
                .concat(_BL_ + "|" + Report.stCenter("TOTALIZADOR", cols - 2) + "|")
                .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
                .concat((pedido.tipoDelivery === "ENTREGAR") ? _BL_ + "TOTAL ITENS " + Report.stR(util.formatNumber(PedidoUtils.totalItem(pedido)), cols - 12) : "")
                .concat((pedido.tipoDelivery === "ENTREGAR") ? _BL_ + "TX. ENTREGA " + Report.stR(util.formatNumber(PedidoUtils.totalTaxaEntrega(pedido)), cols - 12) : "")
                .concat(_BL_ + 'DESCONTO' + Report.stR('-' + util.formatNumber(PedidoUtils.totalDesconto(pedido)), cols - 8))
                .concat(_BL_ + "TOTAL " + Report.stR(util.formatNumber(PedidoUtils.total(pedido)), cols - 6))
        }

        template = template
            .concat(_BL_ + Report.fill(" ", cols))
            .concat(_BL_ + Report.stCenter("qrpedir.com", cols))
            .concat(_BL_ + Report.stCenter("#" + pedido.id, cols))

        //console.log(template);

        while ((template.match(/[^A-z0-9Á-ú-+= /|:!.,*#$]/))) {
            template = `${template}`.replace(/[^A-z0-9Á-ú-+= /|:!.,*#$]/, " ");
        }

        //console.log(template);


        return template
    }

    geraTemplateChamadosPendentes(chamado, cols) {

        /*console.log(chamado);
        console.log("CHAMADO TEMPLATE");*/


        cols -= _BL_.length;

        let identificador = chamado.identificador !== null ? chamado.identificador : ""
        let numeroIdentificador = chamado.numeroIdentificador
        //let horario = chamado.createdAt
        let empresa = chamado.empresa

        let template = ""
            .concat(_BL_ + Report.stCenter(empresa.nome, cols))
            .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
            .concat(_BL_ + "|" + Report.stCenter(`CHAMADO`, cols - 2)) + "|"
                .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")

                .concat(_BL_ + "|" + Report.fill(" ", cols - 2)) + "|"
                    .concat(_BL_ + "|" + Report.stCenter(identificador + " " + numeroIdentificador, cols - 2)) + "|"
                        .concat(_BL_ + "|" + Report.fill(" ", cols - 2)) + "|"
                            .concat(_BL_ + "|" + Report.stCenter(`${chamado.descricao}`, cols - 2)) + "|"
                                .concat(_BL_ + "|" + Report.fill(" ", cols - 2)) + "|"
                                    .concat(_BL_ + "|" + Report.stCenter(`${this.timeFormat(chamado.createdAt)}`, cols - 2)) + "|"
                                        .concat(_BL_ + "|" + Report.fill(" ", cols - 2)) + "|"
                                            .concat(_BL_ + "+" + Report.fill("-", cols - 2) + "+")
                                            .concat(_BL_ + Report.stCenter("qrpedir.com", cols));

        while ((template.match(/[^A-z0-9Á-ú-+= /|:!.,*#$]/))) {
            template = `${template}`.replace(/[^A-z0-9Á-ú-+= /|:!.,*#$]/, " ");
        }

        return template;
    }


    static stR(obj, cols) {
        let key = `%${cols}s`
        return obj !== null ? (key.format(obj)) : key.format('');
    }

    static stL(obj, cols) {
        let key = `%${-cols}s`
        return obj !== null ? (key.format(obj)) : key.format('');
    }

    static stCenter(obj, cols) {
        let midle = Math.round((cols - obj.length) / 2)
        let value = Report.stR(Report.stL(obj, midle + obj.length), cols)
        return value;
    }

    static toDT(date, cols, timezone) {
        if (date === null)
            return Report.stR(date);

        let dt = new Date(date)
        let data = utcToZonedTime(dt, timezone)
        return Report.stR((format(data, 'dd/MM/yy HH:mm')), cols);
    }

    static fill(string, cols) {
        return Array(cols).fill(string).join('')
    }

    timeFormat(time) {
        let date = new Date(time)

        return date.toLocaleString();
    }

}