import AuthService from "../AuthService"
import { Component } from "react";
import Config from "./Config";
import Report from "./Report";
//const { ipcRenderer } = require('electron')
import { ipc } from "../util/electronUtil"


class ChamadoHelper extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    getChamadosPendentes(onFinish, onError) {
        let service = new AuthService()
        let somAtivo = localStorage.getItem("_somAtivo")

        service.get(`chamado/pendentes`)
            .then(chamados => {

                if (chamados.length > 0 && somAtivo === "true") {
                    this.playAudio();
                }

                /*console.log("________CHAMADOS_________");*/
                //console.log(chamados);
                if (chamados.length > 0) {
                    this.imprimeChamados(chamados, onFinish);
                }

            })
            .catch(err => {
                console.log(err);
                if (onError) {
                    onError("Erro ao buscar chamados " + err.message)
                }
            })
    }

    imprimeChamados(chamados, onFinish) {
        let localImpressaoChamados = JSON.parse(localStorage.getItem("_profile")).empresa.localImpressaoChamado;

        if (!localImpressaoChamados)
            throw new Error("É necessário configurar o local de impressão para os chamados")


        let configuracoes = Config.getConfiguracaoImpressoras();

        var localImpressao = null

        configuracoes.locaisImpressao.map(impressao => {
            if (!localImpressao) {
                /*console.log(impressao.chave)
                console.log("__________________");
                console.log(localImpressaoChamados)*/

                if (localImpressaoChamados === impressao.chave) {
                    localImpressao = impressao;
                }
            }
            return null;
        })
        if (!localImpressao) {
            console.log("Não foi possivel encontrar impressora");
            throw new Error("Não foi possivel encontrar impressora para o chamado. Tipo: " + localImpressaoChamados)
        }

        let PrinterName = localImpressao.Printers[0].PrinterName
        let QuantityColumns = localImpressao.QuantityColumns
        let FontSize = localImpressao.FontSize

        /*
        console.log(QuantityColumns);
        console.log(FontSize);*/

        var report = new Report();


        let chamadosNotificados = [];

        chamados.map(chamado => {

            let PrintContent = report.geraTemplateChamadosPendentes(chamado, QuantityColumns)

            if (ipc.ipcRenderer) {

                try {
                    console.log("Chamado será impresso em", PrinterName);
                    let retorno = ipc.sendSync('print-document-await', { PrinterName, PrintContent, QuantityColumns, FontSize })

                    if (retorno) {
                        chamado.statusImpressao = "IMPRESSO";
                        chamadosNotificados.push(chamado);
                        //console.log(chamado);
                    }

                } catch (err) {
                    console.log('ERRO, não foi possivel imprimir o chamado');
                    this.props.enqueueSnackbar('Falha ao imprimir chamado: ' + err, { variant: 'error' })
                }
            }
            return null;
        })

        if (chamadosNotificados && chamadosNotificados.length > 0) {
            this.notificarChamadoImpresso(chamadosNotificados, onFinish);
        }
    }

    notificarChamadoImpresso(chamado, onFinish) {
        let service = new AuthService();

        service
            .post("/chamado/saveAll", chamado)
            .then(resp => {
                console.log("CHAMADO NOTIFICADO");

                onFinish();
            })
            .catch(err => {
                console.log("ERRO " + err);

            })
    }

    playAudio = () => {
        let bellSong = 'http://mattersofgrey.com/audio/DEX-Gen-MainThemeDing.mp3'
        let audio = new Audio(bellSong);
        audio.play();
    }

}

export default ChamadoHelper;