import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import LoginForm from './components/login-form';
import Home from './components/home';
import Sobre from './components/app-about';
import ConfigPrinter from './components/config-printers';
import ConfigSettings from './components/config-settings';

const TOKEN = "id_token";

const isAuthenticated = () => (
  localStorage.getItem(TOKEN) ? true : false
);

const theme = createMuiTheme({
  dividerFullWidth: {
    margin: `5px 0 0 2px`,
  },
  dividerInset: {
    margin: `5px 0 0 2px`,
  },
    palette: {
      primary: {
        main: '#1e2759',
      },
      secondary: {
        light: '#f9e2b9',
        main: '#f2c26a',
        contrastText: '#010e59',
      },
    },
  });


function App() {
  return (
    <div className="App">
      <RouterApp/>
    </div>
  );
}
 
  
const RouterApp = () =>{

  


  return (
    <ThemeProvider theme={theme}>
    <SnackbarProvider maxSnack={3}>
    <CssBaseline />
    
    <Router>
    <Switch>
        <Route exact path="/" component={LoginForm} />
        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/sobre" component={Sobre} />
        <PrivateRoute exact path="/configurarImpressoras" component={ConfigPrinter} />
        <PrivateRoute exact path="/configuracoes" component={ConfigSettings} />
    </Switch>
  </Router>


    </SnackbarProvider>
    </ThemeProvider>
  )

}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isAuthenticated() ? (
      <Route
        {...props}
        component={Component}
      />
    ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
  )} />
)
 

export default App;
