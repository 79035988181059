import React from 'react';
import Button from '@material-ui/core/Button';
import AuthService from '../AuthService'
import Container from '@material-ui/core/Container';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { Box, IconButton } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Config from "../util/Config"
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import HeaderAppBar from "./header"
import DeleteIcon from '@material-ui/icons/Delete';
//const { ipcRenderer } = require('electron')
import { ipc }  from "../util/electronUtil"

class ConfigPrinters extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      locaisNotificacoes: [],
      configuracoes: {
        locaisImpressao: []
      },
      buscando: false
    }

    this.buscarLocaisImpressao = this.buscarLocaisImpressao.bind(this)
    this.buscarImpressoras = this.buscarImpressoras.bind(this)
    this.mostrarImpressoras = this.mostrarImpressoras.bind(this)
    this.handleChangeConfiguracoes = this.handleChangeConfiguracoes.bind(this)
    this.salvarConfiguracoes = this.salvarConfiguracoes.bind(this)

  }

  componentDidMount() {
    this.impressoras = this.buscarImpressoras() || []
    this.buscarLocaisImpressao()
  }

  buscarImpressoras() {
    let prints = ipc.sendSync('show-prints')
    return prints;
  }

  mostrarImpressoras() {
    console.log(this.impressoras)
    console.log(Config.getConfiguracaoImpressoras())
  }


  handleChangeConfiguracoes(event, local) {

    /*console.log(event.target.name)
    console.log(event.target.value)*/
    local[event.target.name] = event.target.value;
    let { configuracoes } = this.state
    this.setState({ configuracoes })

    //console.log(configuracoes)

  };

  handleChangePrinterName = (event, local, index) => {
    let { configuracoes } = this.state

    /*console.log(event.target.name)
    console.log(event.target.value)*/

    local.Printers[index].PrinterName = event.target.value;
    this.setState({ configuracoes })

    //console.log(configuracoes)
  }

  salvarConfiguracoes() {
    //console.log("Salvando as configurações")
    let { configuracoes } = this.state
    Config.salvaConfiguracoes(configuracoes)
    this.props.enqueueSnackbar('Configurações salvas com sucesso.', { variant: 'success' })
    this.props.history.push('/home')
  };

  buscarLocaisImpressao() {
    let service = new AuthService()
    this.setState({buscando: true})
    service
      .get(`localNotificacao/list`)
      .then(resp => {

        let locaisSemNaoNotificar = resp.filter(elem => {
          return "NAO_NOTIFICAR" !== elem.chave
        })

       

        this.setState({ locaisNotificacoes: locaisSemNaoNotificar })

        let configuracoes = Config.getConfiguracaoImpressoras()


        let locaisImpressaoConfigAtualizado = []

        locaisSemNaoNotificar.map(localImpressao => {

          const localEncontrado = configuracoes.locaisImpressao.find(e => { 
              return localImpressao?.chave === e?.chave 
          })

          //console.log("RETORNO Local encontrado", localEncontrado)

          if (!localEncontrado) {
              //ADICIONA LOCAL ENCONTRADO AS CONFIGURACOES
              //console.log("Adicionando local na config")
              //console.log(">>>", localImpressao)
              Config.addLocal(locaisImpressaoConfigAtualizado, localImpressao)
            } else {
              //console.log("Local encontrado")
              //console.log(">>>>", localEncontrado)
              locaisImpressaoConfigAtualizado.push(localEncontrado)
            }
            
            return null;
        })

        configuracoes.locaisImpressao = locaisImpressaoConfigAtualizado;

        //console.log("locaisImpressaoConfigAtualizado", locaisImpressaoConfigAtualizado)
        Config.salvaConfiguracoes(configuracoes)

        //console.log(configuracoes)
        this.setState({ configuracoes })

      }).catch(err => {
        console.log("Erro buscar locais de notificacao", err)

      }).finally(() => {
        this.setState({buscando: false})
      })

  }

  addImpressora = (event, local) => {
    let { configuracoes } = this.state;
    /*console.log(configuracoes.locaisImpressao);

    console.log(local);*/
    let index = configuracoes.locaisImpressao.findIndex(el => el.localObj.id === local.localObj.id);

    let novaImpressora = { PrinterName: "" };
    configuracoes.locaisImpressao[index].Printers.push(novaImpressora);

    /*console.log(index);
    console.log(configuracoes);*/

    this.setState({ configuracoes })
    //console.log(this.state.configuracoes);

  }

  deleteImpressora = (event, local, index) => {
    let configuracoes = Config.getConfiguracaoImpressoras();
    /*console.log(index);
    console.log(local);*/


    local.Printers.splice(index, 1)

    let indexImpressao = configuracoes.locaisImpressao.findIndex(el => el.localObj.id === local.localObj.id);
    configuracoes.locaisImpressao[indexImpressao] = local;

    Config.salvaConfiguracoes(configuracoes);
    this.setState({ configuracoes })
  }


  render() {

    const { configuracoes, buscando } = this.state;

    return (

      <div>
        <HeaderAppBar />

        <Container maxWidth="sm">

          <Box pt={10}>

            <Typography variant="h4" component="h1">
              Configurar impressoras
          </Typography>

            <Typography >
              Configure seus locais de impressão
          </Typography>

          </Box>

          <Box pt={5} pb={5}>
            {buscando && ( 
              <React.Fragment>
                Buscando...
              </React.Fragment>
            )}

            {configuracoes.locaisImpressao.map(local => (
              <ExpansionPanel key={local.chave}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"  >
                  <Typography >{local.localObj.descricao}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>

                  <Grid container spacing={2}>



                    {local.Printers.map((printer, index) => (

                      <React.Fragment key={index}>
                        <Grid xs={local.Printers.length > 1 ? 11 : 12} item  >
                          <FormControl fullWidth>
                            <InputLabel htmlFor="PrinterName-id">Impressora</InputLabel>
                            <Select
                              value={printer.PrinterName}
                              onChange={(event) => this.handleChangePrinterName(event, local, index)}
                              inputProps={{
                                name: 'PrinterName',
                                id: 'PrinterName-id',
                              }}
                            >

                              {this.impressoras.map(print => (
                                <MenuItem key={print.name} value={print.name}>{print.name}</MenuItem>                                
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        {local.Printers.length > 1 ? (
                          <Grid item xs={1}>
                            <IconButton onClick={(e) => this.deleteImpressora(e, local, index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        ) : null}

                      </React.Fragment>
                    ))}

                    <Grid item xs={12}>

                      <Button variant="contained" size="small"
                        disabled={local.Printers.length >= 3} onClick={(e) => this.addImpressora(e, local)}>
                        Adicionar impressora
                          </Button>

                    </Grid>


                    <Grid xs={12} item >
                      <TextField
                        fullWidth
                        id="QuantityColumns"
                        label="Número de colunas"
                        value={local.QuantityColumns}
                        onChange={(event) => this.handleChangeConfiguracoes(event, local)}
                        type="number"
                        name='QuantityColumns'

                        margin="normal"
                      />
                    </Grid>

                    <Grid xs={6} item >
                      <TextField
                        fullWidth
                        style={{ marginLeft: "5px" }}
                        id="FontSize"
                        label="Tamanho da fonte"
                        value={local.FontSize}
                        onChange={(event) => this.handleChangeConfiguracoes(event, local)}
                        type="number"
                        name='FontSize'
                        margin="normal"
                      />
                    </Grid>

                    <Grid xs={6} item >
                      <TextField
                        fullWidth
                        id="qtdImpressoes"
                        label="Quantidade de impressões"
                        value={local.qtdImpressoes}
                        onChange={(event) => this.handleChangeConfiguracoes(event, local)}
                        type="number"
                        name='qtdImpressoes'
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}

          </Box>


          <Button variant="contained" color="primary" onClick={this.salvarConfiguracoes} >
            Salvar configurações
        </Button>



        </Container>
      </div>
    )
  }
}


export default withSnackbar(ConfigPrinters)