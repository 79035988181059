export default class PedidoUtils {

    static totalItem(pedido){

        let total = 0.0;

        if (pedido === null)
            return total;


        pedido.itens.map(item => {
            total += item.valorTotal;
            item.subItens.map(subItem => {
                total += subItem.valorTotal
                return null;
            })
            return null;
        })

        //console.log(`TOTAL ITEM ${total}`)

        return total;
    }

    static totalDesconto(pedido) {
        return pedido.valorDesconto? pedido.valorDesconto: 0
    }


    static totalTaxaEntrega(pedido){

        let total = 0.0;

        if (pedido === null)
            return total;

        if (pedido.tipoDelivery === "ENTREGAR" && pedido.valorTaxaEntrega) {
            total += pedido.valorTaxaEntrega
        }

        //console.log(`TOTAL TAXA ENTREGA ${total}`)

        return total;
    }

    static total(pedido){

        let total = 0.0;

        if (pedido === null)
            return total;

        let totalIt =  this.totalItem(pedido)
        let totalTx =  this.totalTaxaEntrega(pedido)
        let totalDesconto = this.totalDesconto(pedido)

        total = totalIt + totalTx - totalDesconto;

        return  total;
    }


}