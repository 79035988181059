import React from 'react';
import Button from '@material-ui/core/Button';
import AuthService from '../AuthService'
import Container from '@material-ui/core/Container';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { Box, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Report from '../util/Report'
import Config from "../util/Config"
import PedidoIntegracaoHelper from "../util/PedidoIntegracaoHelper"
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import HeaderAppBar from "./header"
import LaunchIcon from '@material-ui/icons/Launch';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChamadoHelper from '../util/compChamadoHelper';
import { ipc } from "../util/electronUtil"
import PrintIcon from '@material-ui/icons/Print';
import PrintDisabledIcon from '@material-ui/icons/PrintDisabled';
import Chip from '@material-ui/core/Chip';

var webNotification = require('simple-web-notification')


//const { ipcRenderer, shell, remote } = require('electron')

const focusCurrentWindow = () => {
  if (ipc.remote) {
    const currentWindow = ipc.remote.getCurrentWindow()
    currentWindow.show()
  } else {
    console.error("IPC não definido. Utiliza o electron")
  }
}

const logElectron = (logContent) => {
  if (ipc.log) {
    ipc.log.log(logContent);
  } else {
    console.log("Versão não compatível com log do electron")
  }
}


class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      buscandoPedidos: false,
      pedidosPendentes: [],

      buscandoPedidosIntegracao: false,
      pedidosIntegracao: [],
      poolingTime: 5000,

      buscarPedidos: true,
      buscarChamados: true,

      stateTab: 0
    }

    this.pararTodasAsBuscas = this.pararTodasAsBuscas.bind(this)

    this.buscarPedidosPendentes = this.buscarPedidosPendentes.bind(this)
    this.pararBusca = this.pararBusca.bind(this)
    this.iniciarBuscas = this.iniciarBuscas.bind(this)
    this.notificarPedidoComoImpresso = this.notificarPedidoComoImpresso.bind(this)
    this.imprimirPedidos = this.imprimirPedidos.bind(this)
    this.iniciarBuscasPausadas = this.iniciarBuscasPausadas.bind(this)
    this.imprimirPedidoNosRespectivosLocais = this.imprimirPedidoNosRespectivosLocais.bind(this)

    this.iniciarBuscasIntegracao = this.iniciarBuscasIntegracao.bind(this)
    this.buscarPedidosPendentesIntegracao = this.buscarPedidosPendentesIntegracao.bind(this)
    this.iniciarBuscasPausadasIntegracao = this.iniciarBuscasPausadasIntegracao.bind(this)

    this.iniciarTodasAsBuscas = this.iniciarTodasAsBuscas.bind(this)
    this.reloadProfile = this.reloadProfile.bind(this)
    this.isComponentMounted = this.isComponentMounted.bind(this)

  }

  componentDidMount() { //quando inicia a tela chama essas funções

    this.componentMounted = true

    let poolingTime = Config.getConfigGerais().poolingTime * 1000
    this.setState({ poolingTime })
    this.reloadProfile();
    this.iniciarTodasAsBuscas()
    this.verificaBuscasAtivas()
  }

  componentWillUnmount() {
    if (this.timerIntegracao) {
      clearInterval(this.timerIntegracao)
    }

    if (this.timer) {
      clearInterval(this.timer)
    }

    this.componentMounted = false
  }

  isComponentMounted() {
    return this.componentMounted;
  }

  reloadProfile() {
    let service = new AuthService()
    service.reloadProfile()
  }

  playAudio = () => {
    let bellSong = "./Assets/somCampainha.wav"
    let audio = new Audio(bellSong);
    audio.play();
  }

  iniciarBuscas() {

    if (!this.isComponentMounted()) {
      return;
    }

    this.timer = setInterval(this.buscarPedidosPendentes, this.state.poolingTime);
    this.setState({ buscandoPedidos: true })

    //this.printChamados()
    //this.timer = setInterval(this.printChamados, this.state.poolingtime)
  }

  iniciarBuscasIntegracao() {

    if (!this.isComponentMounted()) {
      return;
    }

    this.timerIntegracao = setInterval(this.buscarPedidosPendentesIntegracao, this.state.poolingTime);
    this.setState({ buscandoPedidosIntegracao: true })
  }

  iniciarBuscasPausadas() {
    let { buscandoPedidos } = this.state
    if (buscandoPedidos)
      this.iniciarBuscas()
  }

  iniciarBuscasPausadasIntegracao() {
    let { buscandoPedidosIntegracao } = this.state
    if (buscandoPedidosIntegracao)
      this.iniciarBuscasIntegracao()
  }

  pararTodasAsBuscas() {
    this.pararBusca();
    this.pararBuscaIntegracao();
  }

  iniciarTodasAsBuscas() {
    this.iniciarBuscas();
    this.iniciarBuscasIntegracao();
  }

  pararBusca() {
    clearInterval(this.timer)
    this.setState({ buscandoPedidos: false })
  }

  pararBuscaIntegracao() {
    clearInterval(this.timerIntegracao)
    this.setState({ buscandoPedidosIntegracao: false })
  }

  printChamados() {
    /* VERIFICA SE OS CHAMADOS ESTAO ATIVOS NA EMPRESA */
    let service = new AuthService()
    let chamarGarcom = service.getProfile().empresa.chamarGarcom

    if (!chamarGarcom) {
      console.log("Chamado de garçom *INATIVO*.")
      return;
    } else {
      console.log("Chamado de garçom ATIVADO.")
    }

    //clearInterval(this.timerChamado)

    const onFinish = () => {
      //console.log('TERMINOU A IMPRESSAO')
      this.props.enqueueSnackbar("Chamado impresso com sucesso", { variant: "success" })
    }

    const onErrorBuscarChamados = (msgErro) => {
      this.props.enqueueSnackbar(msgErro, { variant: "warning" })
    }

    //try {
    let chamadoHelper = new ChamadoHelper();
    chamadoHelper.getChamadosPendentes(onFinish, onErrorBuscarChamados);
    // } catch (err) {
    //   this.props.enqueueSnackbar("Falha ao imprimir chamado. " + err, { variant: "error" })
    // }
  }


  buscarPedidosPendentesIntegracao() {
    clearInterval(this.timerIntegracao)


    if (!Config.getConfigGerais().ativarIntegracao) {
    // if (true) {
      console.log('INTEGRACAO SISCHEF DESATIVADA.')
      logElectron('INTEGRACAO SISCHEF DESATIVADA.')
      return;
    }

    //console.log('INICIANDO BUSCA PEDIDOS PARA INTEGRAR....')
    logElectron('INICIANDO BUSCA PEDIDOS PARA INTEGRAR....')


    let enqueueSnackbar = this.props.enqueueSnackbar
    let iniciarBuscasPausadasIntegracao = this.iniciarBuscasPausadasIntegracao

    /* INTEGRACAO ATIVADA */
    let service = new AuthService()

    service.get(`pedido/integracaoPendente`)
      .then(pedidos => {

        //console.log("PEDIDOS A SEREM INTEGRADOS", pedidos)

        this.setState({ pedidosIntegracao: pedidos })

        let integracaoHelper = new PedidoIntegracaoHelper()

        integracaoHelper._enviarPedidosParaSischefENotificarIntegracao(pedidos,
          function (retornoNotificados) {
            console.log("PEDIDOS SINCRONIZADOS COM SUCESSO - SICHEF", retornoNotificados)
            logElectron("PEDIDOS SINCRONIZADOS COM SUCESSO - SICHEF")

            iniciarBuscasPausadasIntegracao()
          },
          function (msg, err) {

            enqueueSnackbar(msg + ' - ' + err, { variant: 'error' })
            logElectron(msg + ' - ' + err)


            webNotification.showNotification('Falha na integração', {
              body: 'Falha ao enviar pedido para o terminal Sischef',
              onClick: function onNotificationClicked() {
                //console.log('Notificação clicada.');
                focusCurrentWindow()
              },
              autoClose: 4000 //auto close the notification after 4 seconds (you can manually close it via hide function)
            }, function onShow(error, hide) {
              if (error) {
                window.alert('Sem permissão para mostrar notificação: ' + error.message);
              }
            });

            iniciarBuscasPausadasIntegracao()

          })

      })
      .catch(err => {
        console.error("ERRO AO BUSCAR PEDIDOS PARA SEREM INTEGRADOS", err)
        this.props.enqueueSnackbar('Falha ao buscar pedidos para serem integrados. Verifique sua conexão com a internet', { variant: 'error' })

        this.iniciarBuscasPausadasIntegracao()
      })

  }

  openExternalLink(url) {
    ipc.openExternal(url)
  }

  buscarPedidosPendentes() {
    clearInterval(this.timer)

    if (this.state.buscarChamados) {
      this.printChamados()
    }

    if (!this.state.buscarPedidos) {
      this.iniciarBuscas()
      return;
    }

    let somAtivo = localStorage.getItem("_somAtivo")

    let service = new AuthService()
    service
      .get(`pedido/v2/pendentes`)
      .then(resp => {

        //console.log('RETORNO pedido/v2/pendentes', resp)

        if (resp.length > 0 && somAtivo === "true") {
          this.playAudio();
        }

        this.setState({ pedidosPendentes: resp })
        let { buscandoPedidos } = this.state

        if (buscandoPedidos) {

          //realizar impressao e aguardar retorno
          this.imprimirPedidos(resp)
            .then(pedidosImpressos => {

              //notificar servidor que foram impressos
              //console.log("Ped impress", pedidosImpressos)

              this.notificarPedidoComoImpresso(pedidosImpressos)
                .then(pedidosNotificados => {

                  //console.log('Pedidos notificados como impressos', pedidosNotificados)

                  let { pedidosPendentes } = this.state

                  pedidosNotificados.map(pedidoNotificado => {
                    let posicao = pedidosPendentes.findIndex(p => p.id = pedidoNotificado.id)
                    pedidosPendentes.splice(posicao)

                    return null;
                  })

                  //LIMPA PEDIDOS PENDENTES DO STATE
                  this.setState({ pedidosPendentes })

                  this.iniciarBuscasPausadas()
                }).catch(err => {
                  console.error("Erro ao marcar pedido como impresso", err)
                  this.props.enqueueSnackbar('Falha ao marcar pedido como impresso. Verifique sua conexão com a internet', { variant: 'error' })

                  this.iniciarBuscasPausadas()
                })

            })
            .catch(err => {
              console.error(err)
              this.props.enqueueSnackbar('Falha ao imprimir pedidos', { variant: 'error' })
              this.iniciarBuscasPausadas()
            })



        }

      }).catch(err => {
        console.log("Erro buscar pedidos", err)
        logElectron("Erro buscar pedidos" + err)

        this.props.enqueueSnackbar('Falha ao buscar pedidos para serem impressos. Verifique sua conexão com a internet', { variant: 'error' })

        webNotification.showNotification('Falha ao buscar pedidos', {
          body: 'Falha ao buscar pedidos no qrpedir',
          onClick: function onNotificationClicked() {
            focusCurrentWindow()
          },
          autoClose: 4000 //auto close the notification after 4 seconds (you can manually close it via hide function)
        }, function onShow(error, hide) {
          if (error) {
            window.alert('Sem permissão para mostrar notificação: ' + error.message);
          }
        });


        let { buscandoPedidos } = this.state
        if (buscandoPedidos) {
          this.iniciarBuscas()
        }
      })

  }

  imprimirPedidos(pedidos) {

    return new Promise((resolve, reject) => {
      //console.log("imprimirPedidos(pedidos) ->", pedidos)
      //let pedidosImpressos = []

      if (pedidos === null || pedidos.length === 0) {
        //console.log("Lista de pedidos vazia ->", pedidos)
        return resolve(pedidos)
      }

      let configuracoes = Config.getConfiguracaoImpressoras()

      //SE O PEDIDO FOR DELIVERY ENTRA NO IF
      let responsavelImpressao = configuracoes.gerais.responsavelImpressao

      pedidos.map(pedido => {
        //console.log('pedido a ser impresso -> ', pedido)

        if (pedido.tipoPedido === "DELIVERY" && responsavelImpressao !== 'SISCHEF') {

          let success = this.imprimeDelivery(pedido);

          if (success) {
            pedido.statusImpressao = "IMPRESSO"
            pedido.itens.map(itemPedido => {
              itemPedido.statusImpressao = "IMPRESSO"
              return null;
            })
          }
        }
        return null;
      })

      //SE NÃO FOR DELIVERY ENTRA AQUI
      configuracoes.locaisImpressao.map(localImpressao => {
        //console.log(" -- IMPRIMINDO NO LOCAL: ", localImpressao.chave)

        if (!localImpressao.chave) {
          console.log("Locais de impressão não configurado")
          this.props.enqueueSnackbar('Atenção: Impressoras e locais de impressão não configurados', { variant: 'warning' })
        }

        pedidos.map(pedido => {

          if (responsavelImpressao !== 'SISCHEF') {

            this.imprimirPedidoNosRespectivosLocais(pedido, localImpressao)

            // if (retPedido.statusImpressao === 'IMPRESSO' || retPedido.statusImpressao === 'ATENCAO' ){
            //   console.log('PEDIDO IMPRESSO ', retPedido)
            //   //pedidosImpressos.push(retPedido)
            // }

          } else {

            console.log('Pedidos não enviados para impressora - responsavelImpressao', responsavelImpressao)

            /* MARCA ITENS COMO IMPRESSOS (COMO SE TIVESSEM SIDO MESMO) */
            pedido.statusImpressao = "IMPRESSO"
            let temItensParaEsseLocal = pedido.itens.filter(item => {
              return item.produto.localImpressao === localImpressao.chave
            })

            temItensParaEsseLocal.map(itemPedido => {
              itemPedido.statusImpressao = "IMPRESSO"
              return null;
            })

          }
          return null;
        })
        return null;
      })

      //AJUSTA OS STATUS DOS PEDIDOS
      pedidos.map(pedido => {

        if (!pedido.statusImpressao)
          pedido.statusImpressao = "ATENCAO"

        pedido.itens.map(item => {
          if (!item.statusImpressao)
            item.statusImpressao = "IGNORADO"

          if (item.statusImpressao === 'PENDENTE' && "NAO_NOTIFICAR" === item.produto.localImpressao) {
            item.statusImpressao = "IGNORADO"
          }
          return null;
        })
        return null;
      })

      resolve(pedidos)

    });
  }

  imprimeDelivery = (pedido) => {
    let config = Config.getConfiguracaoImpressoras();
    let indexImpressora = config.locaisImpressao.findIndex(el => el.chave === "DELIVERY");
    let localDelivery = config.locaisImpressao[indexImpressora];

    let success = true;

    localDelivery.Printers.map(impressora => {

      let PrinterName = impressora.PrinterName;
      let QuantityColumns = localDelivery.QuantityColumns;
      let FontSize = localDelivery.FontSize;
      let printQuantity = localDelivery.qtdImpressoes || 1;
      let PrintContent = Report.printPedido(pedido,
        parseInt(localDelivery.QuantityColumns),
        localDelivery.chave,
        localDelivery.localObj)

      console.log("PEDIDO DELIVERY");
      console.log(PrintContent);
      console.log("IMPRESSORA: " + PrinterName);
      console.log("QUANTIDADE DE COLUNAS: " + QuantityColumns);
      console.log("TAMANHO DA FONTE: " + FontSize);


      if (!PrinterName || PrinterName === "") {

        console.log("IMPRESSORA NÂO CONFIGURADA PARA ", localDelivery.chave)
        this.props.enqueueSnackbar('Impressora não configurada para local ' + localDelivery.chave, { variant: 'warning' })

        throw new Error("Impressora não configurada para " + localDelivery.chave)

      } else {

        console.log("INICIANDO IMPRESSAO - COPIAS: " + printQuantity);

        for (var i = 0; i < printQuantity; i++) {
          //console.log("IMPRIMINDO - COPIA: " + i);

          let printContentAsString = JSON.stringify(PrintContent)
          if (printContentAsString.length < 8000){
            
            let retorno = ipc.sendSync('print-document-await', { PrinterName, PrintContent, QuantityColumns, FontSize, pedido })
            if (!retorno) {
              success = false;
            }

          } else {
            console.error("O pedido é muito grande para ser impresso.", pedido)
            success = false;
          }
          
        }

      }
      return null;
    })

    if (success) {
      return true;
    } else return false;
  }

  imprimirPedidoNosRespectivosLocais(pedido, localImpressao) {

    let temItensParaEsseLocal = pedido.itens.filter(item => {
      return item.produto.localImpressao === localImpressao.chave && item.statusImpressao !== "IMPRESSO"
    })

    if (temItensParaEsseLocal.length > 0) {
      try {

        let Printers = localImpressao.Printers
        let QuantityColumns = localImpressao.QuantityColumns
        let FontSize = localImpressao.FontSize
        let PrintQuantity = localImpressao.qtdImpressoes || 1;

        Printers.map(printer => {
          let PrinterName = printer.PrinterName;

          /*console.log("===== IMPRESSORA ======");
          console.log(printer);*/

          for (let x = 0; x < parseInt(PrintQuantity); x++) {


            /* FAZ VALIDACOES NECESSARIAS PARA IMPRESSAO DOS ITENS/PEDIDO */
            if (!PrinterName || PrinterName === "") {

              console.log("IMPRESSORA NÂO CONFIGURADA PARA ", localImpressao.chave)
              this.props.enqueueSnackbar('Impressora não configurada para local ' + localImpressao.chave, { variant: 'warning' })

              throw new Error("Impressora não configurada para " + localImpressao.chave)

            } else {

              //SE TEM IMPRESSORA CONFIGURADA, E TEM ITENS IMPRIME

              if (pedido.identificador.tipo === "DELIVERY" || pedido.identificador.tipo === "SEQUENCIAL") {
                //SE FOR DELIVERY OU SEQUENCIAL, IMPRIME O PEDIDO TODO NO MESMO LOCAL
                pedido.itens.map(itemPedido => {
                  itemPedido.statusImpressao = "IMPRESSO"
                  return null;
                })

              } else {

                temItensParaEsseLocal.map(itemPedido => {
                  itemPedido.statusImpressao = "IMPRESSO"
                  return null;
                })
              }

              console.log("IMPRIMINDO PEDIDO : ", pedido)
              console.log("IMPRESSORA        : ", PrinterName)
              console.log("LOCAL             : ", localImpressao.chave)
              console.log("COLUNAS           : ", parseInt(localImpressao.QuantityColumns))

              let PrintContent = Report.printPedido(pedido,
                parseInt(localImpressao.QuantityColumns),
                localImpressao.chave,
                localImpressao.localObj)


              /* console.log(" ========= PRINT REPORT =======")
               console.log(PrintContent)*/

              let retorno = { success: false };

              if (PrintContent.length < 8000){

                retorno = ipc.sendSync('print-document-await', { PrinterName, PrintContent, QuantityColumns, FontSize, pedido })
              
              } else {
                console.error("O pedido é muito grande para ser impresso (nos locais impressões).", pedido)
              }

              if (retorno.success) {
                pedido.statusImpressao = "IMPRESSO"
              }

            }
          }
          return null;
        })

      } catch (err) {
        console.error("Falha ao imprimir", err)

        //ALTERA STATUS PEDIDO PARA ATENCAO
        pedido.statusImpressao = "ATENCAO"


        this.props.enqueueSnackbar('Falha imprimir pedido: ' + err, { variant: 'error' })
      }

    } else {

      //caso o pedido não tenha itens, adiciona nos impressos tbm
      if (pedido.itens.length === 0) {

        console.log("PEDIDO " + pedido.id + " NÂO POSSUI ITENS. SERÁ MARCADO COMO IMPRESSO ")
        //pedidosImpressos.push(pedido)
        //ALTERA STATUS PEDIDO (IMPRESSAO)
        pedido.statusImpressao = "IMPRESSO"

      } else {
        console.log("PEDIDO " + pedido.id + " POSSUI ITENS - POREM NADA IMPRESSO EM " + localImpressao.chave, pedido)

      }

    }

    return pedido;

  }

  notificarPedidoComoImpresso(pedidos) {

    return new Promise((resolve, reject) => {

      if (!pedidos || pedidos.length === 0)
        return resolve([]);


      //altera formato para enviar só os dados necessários
      let pedidosASeremAlterados = []

      pedidos.map(pedido => {
        let pedidoASerAlterado = {
          id: pedido.id,
          statusImpressao: pedido.statusImpressao,
          itens: []
        }

        let itens = (pedido.itens || [])
        itens.map(item => {
          pedidoASerAlterado.itens.push({
            id: item.id,
            statusImpressao: item.statusImpressao
          })
          return null;
        })

        pedidosASeremAlterados.push(pedidoASerAlterado)
        return null;
      })

      let service = new AuthService()
      service
        .post("/pedido/alterarStatusImpressao", pedidosASeremAlterados)
        .then(resp => {
          console.log(`${resp.length} pedidos marcado como impresso`, resp)

          return resolve(resp)
        }).catch(err => {
          return reject(err);
        })
    })
  }

  //Pega no local storage os valores salvos de buscar chamados e buscar pedidos e depois salva no state
  verificaBuscasAtivas() {
    let chamados = localStorage.getItem("_buscarChamados") === null ? "true" : localStorage.getItem("_buscarChamados");
    let pedidos = localStorage.getItem("_buscarPedidos") === null ? "true" : localStorage.getItem("_buscarPedidos");

    if (chamados === "true") {
      this.setState({ buscarChamados: true })
    } else {
      this.setState({ buscarChamados: false })
    }

    if (pedidos === "true") {
      this.setState({ buscarPedidos: true })
    } else {
      this.setState({ buscarPedidos: false })
    }
  }


  render() {

    const { pedidosPendentes, pedidosIntegracao } = this.state
    const { buscandoPedidos } = this.state
    const { buscarChamados, buscarPedidos } = this.state

    return (
      <React.Fragment >
        <HeaderAppBar />

        <Container maxWidth="sm">

          <Box pt={10}>
            <div>
              <Typography variant="h4" component="h1">
                Monitor de impressões
              </Typography>
              <Typography >
                Mantenha esta tela sempre aberta para receber os pedidos automáticamente na impressora
              </Typography>

              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Box pt={5}>
                    <Paper >
                      <Box p={3}>
                        {buscandoPedidos ?
                          <div>
                            <Grid container alignItems="center">
                              <Grid item xs={6}>
                                <Button onClick={this.pararTodasAsBuscas} > <StopIcon /> Pausar buscas</Button>
                              </Grid>

                              <Grid item xs={6}>
                                <LinearProgress />
                              </Grid>
                            </Grid>

                          </div> :
                          <Button onClick={this.iniciarTodasAsBuscas} ><PlayArrowIcon /> Iniciar buscas</Button>
                        }
                      </Box>
                    </Paper>
                  </Box>
                </Grid>

              </Grid>

            </div>
          </Box>


          <Box pt={2} px={2}>

            <Grid container spacing={2}>
              <Grid item >
                {buscarPedidos ? (
                  <Chip size="small" color="primary" icon={<PrintIcon color="primary" size="small" />} label="Busca de pedidos ativa" />
                ) : (
                  <Chip size="small" color="primary" icon={<PrintDisabledIcon color="primary" size="small" />} disabled label="Busca de pedidos inativa" />
                )}

              </Grid>
              <Grid item >
                {buscarChamados ? (
                  <Chip size="small" color="secondary" icon={<PrintIcon color="primary" size="small" />} label="Busca de chamados ativa" />
                ) : (
                  <Chip size="small" color="secondary" icon={<PrintDisabledIcon color="primary" size="small" />} disabled label="Busca de chamados inativa" />
                )}
              </Grid>
            </Grid>
          </Box>

          <Box pt={3}>


            <Tabs
              value={this.state.stateTab}
              onChange={(event, newValue) => { this.setState({ stateTab: newValue }) }}
              indicatorColor="primary"
              textColor="primary"
              aria-label="Tipo de informação" >
              <Tab label="Impressão" />
              <Tab label={`Integração ${Config.getConfigGerais().ativarIntegracao ? '' : ' (inativo)'}`}
                disabled={!Config.getConfigGerais().ativarIntegracao} />
            </Tabs>


            <TabPanel value={this.state.stateTab} index={0}>
              <PedidosImpressao pedidos={pedidosPendentes} />
            </TabPanel>

            <TabPanel value={this.state.stateTab} index={1}>
              <PedidosIntegracao pedidos={pedidosIntegracao} />
            </TabPanel>



          </Box>

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid
              item >
              <Box pt={2}>
                <Button size="small" onClick={() => this.openExternalLink('https://station.qrpedir.com')}>
                  <LaunchIcon style={{ fontSize: 16 }} /> Mostrar últimos pedidos impressos
                </Button>
              </Box>
            </Grid>
          </Grid>

        </Container>
      </React.Fragment>
    )
  }
}

function EmptyRow() {
  return (
    <TableRow>
      <TableCell align="center"></TableCell>
      <TableCell component="th" scope="row">
        <Typography variant="caption" display="block" gutterBottom>
          nenhum pedido para ser impresso no momento
        </Typography>
      </TableCell>
    </TableRow>
  )
}


function PedidosImpressao(props) {

  const { pedidos } = props

  return (
    <Paper >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" align="center">Mesa/Comanda</TableCell>
            <TableCell component="th">Pedido pendentes</TableCell>
            <TableCell component="th"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pedidos.length === 0 ? <EmptyRow /> :
            (
              pedidos.map(pedido => (
                <TableRow key={pedido.id}>
                  <TableCell align="center"> {pedido.identificador.numero}</TableCell>
                  <TableCell scope="row">
                    {pedido.nomeCliente}
                  </TableCell>

                  <TableCell scope="row">
                    {pedido.itens.length} item(ns)
                  </TableCell>
                </TableRow>
              ))
            )}
        </TableBody>
      </Table>
    </Paper>

  )
}


function PedidosIntegracao(props) {

  const { pedidos } = props

  return (
    <Paper >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" align="center">Mesa/Comanda</TableCell>
            <TableCell component="th">Integrando</TableCell>
            <TableCell component="th">Status</TableCell>
            <TableCell component="th">Msg</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pedidos.length === 0 ? <EmptyRow /> :
            (
              pedidos.map(pedido => (
                <TableRow key={pedido.id}>
                  <TableCell align="center"> {pedido.identificador.numero}</TableCell>
                  <TableCell scope="row">
                    {pedido.nomeCliente}
                  </TableCell>

                  <TableCell scope="row">
                    {pedido.statusIntegracao}
                  </TableCell>


                  <TableCell scope="row">
                    {pedido.msgIntegracao}

                  </TableCell>
                </TableRow>
              ))
            )}
        </TableBody>
      </Table>
    </Paper>

  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </Typography>
  );
}

export default withSnackbar(Home)