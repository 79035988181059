import React from 'react';
//const { shell } = require('electron')

export default function AppAbout(props) {
  const appName = require('../../package.json').name
  const appDescription = require('../../package.json').description
  const appVersion = require('../../package.json').version
  /*
  const appLicense = require('../../package.json').license
  const appDepends = require('../../package.json').dependencies*/

  /*
  function showDepends(){
    let depends = JSON.stringify(appDepends)
    depends = depends.substr(1, depends.length-2)
    return depends.replace(/\,/g, "\n")
  }

  function openLink(link){
    //shell.openExternal(link)
  } */

  return (
    <div>
      <h2>Sobre o qrpedir</h2>
      <b>Desenvolvido Parseint Desenvolvimento de Sistemas</b><br/><br/>

      <b>Nome:</b> {appName} - {appDescription}<br/>
      <b>Versão:</b> {appVersion}<br/>
      <b>Criado a partir do projeto de </b> Ricardo Mansano - https://github.com/ricardomansano/<br/>
    </div>
  );
}