import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import AuthService from '../AuthService';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

class LoginForm extends React.Component{
  
  constructor(props){
    super(props)

    this.state = {
        login: '', 
        password: '',
        enviando: false
    }

    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
  }

  componentDidMount(){
    let service = new AuthService()
    if (service.loggedIn()){
      console.log("Usuario logado")
      this.props.history.push('/home')
    }else{
      console.log("Usuario não logado")
    }
  }
 
  onClickSubmit = (e) => {
    e.preventDefault();
    //console.log('Submit')

    let { login, password } = this.state
    //console.log(login, password)
    
    this.setState({enviando: true})

    let service = new AuthService()
    service
      .login(login, password)
      .then(resp => {
        this.setState({enviando: false})
        //console.log(resp)
        this.props.enqueueSnackbar('Login realizado com sucesso.', {variant: 'success'})
        this.props.history.push('/home')
      }).catch(err => {
        console.log(err)
        this.setState({enviando: false})
        this.props.enqueueSnackbar('Falha ao logar', {variant: 'error'})
      })

  }

  onTextChange = (e) => {
    // SetState com nome dinamico
    const {id, value} = e.target
    this.setState({[id]: value})
  }
 

  render(){
    const { enviando } = this.state;

    return ( 
      
      <Container maxWidth="sm">
      <Box pt={10}> 

      <Typography variant="h4" component="h1">
      Monitor de impressão - qrpedir
      </Typography>
      <Typography >
      Realize o login
      </Typography>


        <Box mt={10}> 
        <form onSubmit={(event) => {this.onClickSubmit(event)}}>
        <Card >
        <CardContent >
            <TextField
                required id="login" value={this.state.login}  fullWidth 
                label="Login" onChange={(event) => {this.onTextChange(event)}}
            />
            <TextField
                required id="password" value={this.state.password} fullWidth type="password"
                label="Senha" onChange={(event) => {this.onTextChange(event)}}
            />

        </CardContent>
        <CardActions>
          <Button type="submit" size="large" color="primary" variant="contained" disabled={enviando}>
           {enviando ? 'Acessando...' : 'Acessar'}
          </Button> 
        </CardActions>
        </Card>
        </form> 
        </Box>
        </Box>
        </Container>
    )
  }
}

export default withSnackbar(LoginForm);

