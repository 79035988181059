
const CONFIG_PRINT_KEY = "_CONFIGURACAO_IMPRESSORAS_";
    
export default class Config {

    static getConfiguracaoImpressoras(){

        let config = localStorage.getItem(CONFIG_PRINT_KEY)
        if (config === null){
            config = {
                locaisImpressao: [
                    {
                        chave: "",
                        Printers: [{PrinterName: ""}],
                        QuantityColumns: 40,
                        localObj: { descricao : ""},
                        FontSize: 8,
                        qtdImpressoes: 1
                    }
                ],
                gerais: {
                    urlApi: '',
                    poolingTime: 5,

                    ativarIntegracao: false,
                    urlApiIntegracao: '',
                    responsavelImpressao: 'QRPEDIR' /* QRPEDIR, SISCHEF, QRPEDIR_SISCHEF */
                }
            }
            this.salvaConfiguracoes(config)
        }else{
            config = JSON.parse(config)
        }
    
        return config
    }

    static addLocal(locais, local){
        locais.push({
            chave: local.chave,
            localObj: local,
            Printers: [{PrinterName: ""}],
            QuantityColumns: 40,
            FontSize: 8,
            qtdImpressoes: local.qtdImpressoes
        })
    }

    static salvaConfiguracoes(configuracoes){
        localStorage.setItem(CONFIG_PRINT_KEY, JSON.stringify(configuracoes))
    }

    static getConfigGerais(){
        return Config.getConfiguracaoImpressoras().gerais
    }
}



